<template >
<div>
    <div class="info-box  bg-sheme  text-white">
        <div class="info-icon bg-sheme-dark" style="padding:5px;">
            <h5>  <i class="fa fa fa-user fa-2x"></i>Followed by details</h5>
        </div>
        <div class="info-details">
            <div class="col-md-12">
                <h5>Followed ID</h5>
            </div>
        </div>
    </div>
    <div>
        <table class="table table-striped-sm-bordered-hover-inverse table-inverse">
        <thead class="table-font">
            <tr>
                <th>#</th>
                <th>Followed by</th>
                <th>Updated by</th>
                <th>Date</th>
            </tr>
        </thead>
        <tbody class="table-font">
            <tr v-for="(item,index) in followedby" v-bind:key="item.id">
                <td>{{ index+1 }}</td>
                <td><div v-if="item.user!=null">{{item.user.name }}</div></td>
                <td><div v-if="item.enteredby!=null">{{item.enteredby.name }}</div></td>
                <td>{{ moment(item.created_at).format("DD-MM-YYYY") }}</td>
            </tr>
        </tbody>
        </table>
    </div>


</div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
export default {
    mixins:[Constants],
    data() {
        return {
            moment:moment
        }
    },
    methods:{
        back(){
            this.localviewno=1;
            this.$store.commit("assignviewno",2)
        },
    },
    computed:{
        ...mapGetters([
		    'edititem'
	    ]),
        followedby(){
            if(this.edititem!=null && this.edititem.followed!=null && this.edititem.followed.length>0){
                return this.edititem.followed;
            }
            return [];
        }
    }
}
</script>