<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="fa fa fa-list-alt animated bounceInDown show-info"></i>
                Scheduled EMI
                </h3>
            </div>
            <div class="col-md-6 ">
                <div class="row" style="padding-top:18px;justify-content:flex-end;">
                    <div class="col-md-2" style="margin-right:25px;">             
                        <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-md-10 pt-0 pb-10">
            <div class="form-horizontal">
                <div class="form-group">
                <label class="col-lg-4 co-md-3 control-label" style="text-align:end;"><b> Search Records</b></label>
                    <div class="col-lg-8 col-md-9 pull-right ">
                    <input id="myInput" onkeyup="searchTable()" type="text" class="form-control form-cascade-control input-small">
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div v-if="viewno==1">
        <div>
            <div class="row mb-20">
                <div class="col-md-4">
                    <label>Lan No.</label>
                    <input type="text" v-model="lanno" class="form-control form-cascade-control input-small">
                </div>
                <div class="col-md-4">
                    <label>VSDIGI-ID</label>
                    <input type="text" v-model="vsdigiid" class="form-control form-cascade-control input-small">
                </div>
                <div class="col-md-4">
                    <label>Customer Name</label>
                    <input type="text" v-model="name" class="form-control form-cascade-control input-small">
                </div>
                <div class="col-md-4">
                    <label>Mobile no.</label>
                    <input type="number" v-model="mobile" class="form-control form-cascade-control input-small">
                </div>
               
                <div class="col-md-4">
                    <label>From Date</label>
                    <input type="date" v-model="fromdate" class="form-control form-cascade-control input-small">
                </div>
                <div class="col-md-4">
                    <label>To Date</label>
                    <input type="date" v-model="todate" class="form-control form-cascade-control input-small">
                </div>
                <div class="col-md-4 flex-around-row" style="margin-top: 23px;">
                    <button class="btn btn-success" @click="search()" >Search</button>
                    <button class="btn btn-danger" @click="cancel()" >Cancel</button> 
                </div>
            </div>
            
        </div>
        <div class="row" >
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body" style="overflow-x:auto;">
                        <table class="table users-table table-condensed table-hover" id="tblmanual">
                            <thead class="table-font">
                                <tr>
                                    <th>S.No</th>
                                    <th>Scheme Id</th>
                                    <th>VSDIGI-ID</th>
                                    <th>Customer</th>
                                    <th>Amount</th>
                                    <th>Followed by</th>
                                    <th>Emi Date</th>
                                    <th>Scheduled Date</th>
                                    <th>Messages</th>
                                </tr>
                            </thead>
                            <tbody class="table-font">
                                <tr v-for="(item,index) in list" v-bind:key="item.id">
                                    <td>{{index+1}}</td>
                                    <td>
                                        <div v-if="item.emi.ongoingscheme!=null">
                                            <a href="#"  @click="details(item)">{{item.emi.ongoingscheme.generatedid}}</a>
                                        </div>
                                    </td>
                                    <td><div v-if="item.emi.ongoingscheme.customer!=null">{{item.emi.ongoingscheme.customer.generatedid}}</div></td>
                                    <td><div v-if="item.emi.ongoingscheme.customer!=null">{{item.emi.ongoingscheme.customer.name}}</div></td>
                                    <td>{{item.emi.amount}}</td>
                                    <td>{{item.emi.ongoingscheme.primaryfollowed!=null?item.emi.ongoingscheme.primaryfollowed.user!=null?item.emi.ongoingscheme.primaryfollowed.user.name:'':''}}</td>
                                    <td>{{item.emi.emidate}}</td>
                                    <td>{{ item.scheduleddate }}</td>
                                    <td><button class="badge bg-sheme" type="button" @click="visitcomment()"><i class="fa fa-envelope"></i> {{ getvisitcount(item.emi) }} </button></td>
                                </tr>
                                <tr v-if="visitcomments">
                                    <td colspan="9">
                                        <emi-visit-comment :emiid="item.id" ></emi-visit-comment>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <on-going-scheme-details v-if="viewno==3"></on-going-scheme-details>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import EmiVisitComment from '../../components/emi/EmiVisitComment.vue'
import Constants from '../../components/utilities/Constants.vue'
import OnGoingSchemeDetails from '../ongoingschemes/OnGoingSchemeDetails.vue'
export default {
    components:{EmiVisitComment,OnGoingSchemeDetails},
    mixins:[Constants],
    props:['emiid'],
    data(){
        return {
            list:[],
            visitcomments:false,
            lanno:'',
            vsdigiid:'',
            name:'',
            todate:'',
            fromdate:'',
            mobile:0,
        }
    },
    computed:{
		...mapGetters([
		    'viewno','parties','edititem','OnGoingSchemeDetails'
		]),
	},
    mounted(){
		this.refresh();
	},
    methods:{
       
        refresh(){
            this.$http.post('api/scheduled/fetch')
            .then((response) => this.processResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processResponse(data){
            this.list = data
        },
        getvisitcount(emi){
            if(emi.visitcomments!=null){
                return emi.visitcomments.length
            }
            return 0
        },
        visitcomment(){
            this.visitcomments = true;
        },
        details(item){
            // console.log(this.list)
            let param = {id:item.emi.ongoingschemeid}
            this.$http.post('api/ongoingschemes/details',param)
            .then((response) => this.processDetailResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processDetailResponse(data){
            console.log(data)
            // this.$store.commit('assignidproofs',data.customer.idproofs==null?[]:data.customer.idproofs)
            this.$store.commit('assignedititem',data)
            this.$store.commit('assignviewno',3)
        },
        initialize(){
            this.lanno='',
            this.vsdigiid='',
            this.name='',
            this.fromdate=''
            this.todate='',
            this.mobile=0
        },
        cancel(){
            this.initialize();
            this.refresh();
        },
        search(){
            let param = {
                lanno:this.lanno,
                vsdigiid:this.vsdigiid,
                name:this.name,
                fromdate:this.fromdate,
                todate:this.todate,
                mobile:this.mobile
            }
            this.$http.post('api/scheduled/searchscheduleemi',param)
            .then((response) => this.processSearchResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processSearchResponse(data){
            this.list = data
        }
    }

}
</script>
<style>
.btn-primary:hover{
    text-decoration: none !important;
    background-color: #3a476b !important;
}
</style>